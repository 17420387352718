import React from 'react'
import HeroBanner from '../../component/herobanner/HeroBanner'
import './HomePage.css'
const HomePage = () => {
  return (
    <div>
      <HeroBanner/>
    </div>
  )
}

export default HomePage
